
export default {
  props: {
    itemsSelect: {
      type: Array,
      default: function () {
        return []
      }
    },

    labelSelects: {
      type: String,
      default: 'Selecione'
    },
    configFilter: {
      type: Object,
      default: () => {}
    },
    addlFilter: {
      type: Boolean,
      default: false
    },

    labelTextField: {
      type: String,
      default: 'Busca por nome:'
    }
  },

  methods: {
    buscar (val) {
      this.$emit('search', val)
    }
  }
}